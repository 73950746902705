<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="70" style="
    position: fixed;
    z-index: 2 !important;
    backdrop-filter: blur(10px) brightness(0.9) contrast(0.9) saturate(0.65);
    box-shadow: rgb(0 0 0 / 46%) 0px 0px 52px !important;
    border-bottom: 1px solid rgb(75 75 75 / 42%) !important;
    max-width: 100vw;
    ">
      <span class="text-button env-span" v-if="deploymentEnvironment.includes('stage.deliverdooh')">
        <v-icon style="color: #22a4d1;font-size: larger;">mdi-theater</v-icon>
        Stage Environment
      </span>
      <span class="text-button env-span" v-else-if="deploymentEnvironment.includes('localhost')">
        <v-icon style="color: #ffaa00;font-size: larger;">mdi-lan</v-icon>
        Local Environment
      </span>
    <div class="nav-master-con">
  <div class="nav-left-con">
    <div class="nav-left-1">
      <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value">mdi-menu</v-icon>
      <v-icon v-else>mdi-close</v-icon>
    </v-btn>
    </div>
    <div class="nav-left-2">
      <CampaignTabs />
    </div>
  </div>
  

  <div class="nav-right-con">
    <div class="nav-right-1 d-flex">

      <!-- Assume Role dropdown -->
   <div>
    <v-select
      v-if="roles.length > 0"
      v-model="selectedRole"
      :items="roles"
      item-text="name"
      item-value="id"
      dense
      hide-details
      @change="changeRole"
      style="width: 200px; margin-right: 10px;"
    ></v-select>
   </div>

      <!-- <ThemeModeSwitch  style="padding-top: 10px;padding-right: 10px;"/> -->
      
    <div id="attachId" v-if='$store.state.Permissions.todoBellNotifications' style="padding-top: 8px;">
    <v-menu
      v-model="menu"
      transition="slide-y-transition"
      offset-y
      offset-x
      min-width="550"
      style="min-height: 300px;"
      max-width="550"
      :close-on-select="false"
      :close-on-click="false"
      :close-on-content-click="false"
      fixed
      :absolute="false"
    >
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{hover}">
          <v-btn
            icon
            class="text-body"
            style="margin-right:25px"
            v-on="on"
            small
          >
            <v-icon class="material-icons-round" size="22">
              notifications
            </v-icon>
            <v-badge
              color="#f44335"
              :content="redAlertCount"
              offset-x="1"
              offset-y="0"
              class="position-absolute"
              bordered
            />
          </v-btn>
        </v-hover>
      </template>

      <v-list class="pa-1" style="background-color: all 0.3s ease-in-out">
         
        <v-list-group
          v-for="item in notificationsHeaders"
          :key="item.type.name"
          v-model="item.active"
          @click.stop=""
          no-action
          :style="{
            'max-width': '550px', 
            'max-height': '300px', 
            'overflow-y': 'auto', 
            'overflow-x': 'hidden', 
            'transition': 'all 0.3s ease-in-out',
            // Hide the scroll bar
            'scrollbar-width': 'none',
            '-ms-overflow-style': 'none',
          }"
        >
          <!-- Header item-->
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">{{item.type.name + ' (' + item.notifications.length + ')'}}</v-list-item-title>
            </v-list-item-content>
          </template>
          
          <!-- Child items-->
          <v-list-item
            v-for="(child, index) in item.notifications"
            :key="index"
            class="pl-4 mb-10"
            @click="openIssueTaskInCampaignStatus(getIssueIdHook(child.hooks, 'CampaignId'), getIssueIdHook(child.hooks, 'CampaignBurstId'), getIssueIdHook(child.hooks, 'IssueId'))"
            >
            <v-list-item-content>
                  <v-list-item-title>
                    
                  <span style="font-weight: 800;">{{index+1 }}. {{ child.title }}</span>
                  <br/>
                  {{ child.message }}</v-list-item-title>
                  
            </v-list-item-content>
            
          </v-list-item>

          <!-- 0 Notifications text-->
          
          <v-row
            no-gutters
            class="d-flex justify-center"
            v-if="item.notifications.length === 0"
          >
            <v-col
              cols="12"
              class="text-center"
            >
              <span class="text-h6">No notifications</span>
            </v-col>
          </v-row>

        </v-list-group>
        <!-- Refresh + Loader -->
        <v-row 
          style="
            max-width: 550px;
            max-height: 300px;
            display: flex;
            justify-content: end;
            padding-right: 10px;
          "
          no-gutters
        >
          <!-- Loader -->
          <v-progress-circular
            v-if="notificationLoader"
            indeterminate
            size="28"
            color="primary"
            class="ml-0 mr-4"
          ></v-progress-circular>

          <!-- Refresh button -->
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click.stop="getNotifications"
                v-bind="attrs"
                v-on="on"
                class="ml-0"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>

        </v-row>
      </v-list>
    </v-menu>
 
 
  </div>
  <v-icon
      @click="logoutUser"
      class="mouse-pointer mx-1"
      style="height: 20px; padding-top: 22px;"
    >
      mdi-logout
    </v-icon>
    </div>
    <div class="nav-right-2"  ref="signalrNav">
      <!-- SIGNALR BLOCK -->
      <!-- <CampaignOptions /> -->
      <SystemVersion/>
      <div key="nav2" style="position: relative;" ref="signalrNavInner" >
        <ConnectionStatus style="position: absolute; top: 0; right: 7px;"/>
        <ActiveDirectory :navmaxitems="navMaxItems" style="width: fit-content;overflow: visible;" />
      </div>
    </div>
  </div>
</div>
  </v-app-bar>
</template>

<script>

// Controllers
import IssueTask from '@/services/controllers/IssueTask'
import UserController from "@/services/controllers/User";
import StakeholderController from '@/services/controllers/Stakeholder'
// Components
import ThemeModeSwitch from '@/components/_theme/ModeSwitch'
import ConnectionStatus from '@/components/_theme/ConnectionStatus'
import ActiveDirectory from '@/components/_theme/ActiveDirectory'
import SystemVersion from '@/components/_theme/SystemVersion'
import CampaignOptions from '@/components/_theme/CampaignOptions'
import CampaignTabs from '@/components/_theme/CampaignTabs'
// Vuetify Lib
import { VHover, VListItem } from "vuetify/lib";
// Global Storage (Vuex)
import { mapMutations, mapGetters } from "vuex";
// Mixins
import localStorage from '@/mixins/local/storage'

// SignalR
// SIGNALR BLOCK //
import { signalr } from '@/main.js'


export default {
  name: "DashboardCoreAppBar",

  mixins: [
      localStorage,
    ],

  components: {
    AppBarItem: {
        render(h) {
            return h(VHover, {
                scopedSlots: {
                    default: ({ hover }) => {
                        return h(VListItem, {
                            attrs: this.$attrs,
                            class: {
                                "black--text": !hover,
                                "white--text secondary elevation-12": hover,
                            },
                            props: {
                                activeClass: "",
                                dark: hover,
                                link: true,
                                ...this.$attrs,
                            },
                        }, this.$slots.default);
                    },
                },
            });
        },
    },
    ThemeModeSwitch,
    ConnectionStatus,
    ActiveDirectory,
    SystemVersion,
    CampaignOptions,
    CampaignTabs,
},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    profile: [
      { title: "Profile", to: "/user" },
      { divider: true },
    ],
    menu: false,
    notificationLoader: false,
    notificationsHeaders: [],
    redAlertCount: 0,
    navWidth: 0,
    navMaxItems: 1,
    deploymentEnvironment: window.location.origin,
    roles: [],
    selectedRole: null,
    actualRole: {
      roleId: 0,
    },
  }),
  mounted() {
    this.getNotifications();
    // so that it's only subscribed once
    // SIGNALR BLOCK //
    this.liveBellNotificationsListener();
    // Set up a callback for state changes
    signalr.onStateChange((newState, error) => {
      if (newState === 'Connected') {
        this.liveBellNotificationsListener();
      }
      else{
        console.log('Cant connect to SignalR to send notifications');
      }
    });


    this.navWidth = this.$refs.signalrNav.clientWidth
    this.ro = new ResizeObserver(this.onResizeNavWidth)
    this.ro.observe(this.$refs.signalrNav)
  },
  async created() {
    this.initVueLocal(['campaignViewType'])
    await this.getAssumableRoles();
    await this.getUser()
  },
  computed: {
    ...mapGetters('UI',
      ["drawer"]
    ),
    getIssueIdHook() {
      return (hook, type) => {
        const foundItem = hook.find((item) => item.type === type);
        const itemId = foundItem ? foundItem.value : null;
        return itemId
      }
    }
  },
  
  methods: {
    ...mapMutations('UI',
      { setDrawer: "SET_DRAWER" }
    ),
    async liveBellNotificationsListener() {
      signalr.connection.on("ReceiveActiveNotifications", (response) => {  
      this.setNotifications(JSON.parse(response))
    },);
    },

    async changeRole() {
      await UserController.assumeRole(this.selectedRole)
      .then(res => {
        // Set logged in user's role
        // snackbarSuccess role has been changed
        this.$root.$emit('snackbarSuccess',res.data.message)
        // reload page after 1 second
        setTimeout(() => {
          location.reload()
        }, 1000)
      })
      .catch(err => {
        // Catch and display snackbar error message 
        this.$root.$emit('snackbarError',err.response.data.message)
      })
    },

    async getAssumableRoles () {
        await StakeholderController.getAssumableRoles().then((res) => {
          console.log("roles", res.data)
          this.roles = res.data
        })
      },

    async getUser() {
      const res = await UserController.whoAmI()
      .then(async res => {
        // Set logged in user's role
        console.log("user", res.data)
        const roleData = {
          roleId: res.data.roleId,
          roleName: res.data.roleName,
        }
        this.actualRole = Object.freeze(roleData)
        // is there a role assumed?
        const roleAssumed = await UserController.getAssumedRole().then(resA => {
          return resA.data
        })
        this.selectedRole = roleAssumed
        console.log("actualRole", this.actualRole)
      })
      .catch(err => {
        // Catch and display snackbar error message 
        
      })

      if(res && res.data && res.data.contactCard) {
        // Set logged in user id and stakeholder id
        this.loggedInUserStakeholderId = res.data.contactCard.stakeholder.id
        this.loggedInUserId = res.data.contactCard.id
      }
    },

    onResizeNavWidth () {
      var newClientWidth = this.$refs.signalrNav.clientWidth - 50
      if (this.navWidth != newClientWidth){
        this.navWidth = newClientWidth
        this.$emit('resize', this.navWidth)
        var newNavMaxItems = 1;
        if (this.navWidth && this.navWidth >= 90) {
        newNavMaxItems = Math.floor(this.navWidth / 45);
        }
        if (this.navMaxItems != newNavMaxItems){
          this.navMaxItems = newNavMaxItems
        }
      }
  },
    
    async logoutUser() {
      // LOGOUT BLOCK //
      await UserController.logout({
        username: this.getItem("username"),
        refreshToken: this.getItem("refreshToken"),
      })

      this.removeItem("username");
      this.removeItem("refreshToken");

      // Redirect to login page If not on pop.dooh.com
      const currentHref = window.location.href

      if(currentHref.includes('pop.dooh.com') || currentHref.includes('popstage.deliverdooh.com')) {
        this.$router.push({ name: 'PopGalleryLand' })     
      }
      else {
        this.$router.push({ name: 'Login' })
      }
    },

    async getNotifications() {
      try {
        this.notificationLoader = true;
        const res = await IssueTask.getBellNotifications(0);
        // Check if there are any notifications
        if (res.data.bellSections.length === 0) { // on first load
          this.notificationsHeaders = [];
          this.notificationsHeaders = res.data.bellSections;
        } else { // on refresh
            this.setNotifications(res.data);
          }
      } catch (e) {
      }
      finally {
        this.notificationLoader = false;
      }
    },
    // Create a mutator method
    setNotifications(data) {
        const activeIndex = this.notificationsHeaders.findIndex((item) => item.active === true);
        this.notificationsHeaders = [];
        this.notificationsHeaders = data.bellSections
        this.redAlertCount = data.redAlertCount;

        // Set the active entry to the same index
        if(activeIndex > -1)
            this.notificationsHeaders[activeIndex].active = true;
    },

    navigateToNotifications() {
      this.$router.push({ name: 'Notifications' })
    },
    // Function to navigate to campaign status on dashboard, and then open the relevant issue task in the relevant campaign and burst (open the modal at the corrent index)
    openIssueTaskInCampaignStatus(campaignId, campaignBurstId, issueId) {
      // View Selected
      this.$store.commit('UI/SET_CAMPAIGN_VIEW', 'progress');
      this.$store.commit('UI/SET_CAMPAIGN_ID', campaignId);
      this.$store.commit('UI/SET_CAMPAIGN_BURST_ID', campaignBurstId);
      this.$store.commit('UI/SET_ISSUE_ID', issueId);
      this.$store.commit('UI/SET_ISSUE_TASK_MODAL', true);

      // Push to Route
      if(this.$route.name !== 'Campaigns')
        this.$router.push({ name: 'Campaigns' })

      this.setVueLocal('campaignViewType', 'progress')
    }
  },
};
</script>

<style scoped>

.nav-master-con {  
  width: 100%;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 70px;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: column;
  grid-template-areas:
    "nav-left-con nav-right-con";
}

.nav-left-con {  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "nav-left-1 nav-left-2";
  grid-area: nav-left-con;
}

.nav-left-1 { grid-area: nav-left-1; }

.nav-left-2 { grid-area: nav-left-2; overflow: hidden; }

.nav-right-con {  display: grid;
  grid-template-columns: calc(100% - 81px) min-content;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-content: end;
  grid-template-areas:
    "nav-right-2 nav-right-1";
  grid-area: nav-right-con;
}

.nav-right-1 { grid-area: nav-right-1; }

.nav-right-2 { 
  grid-area: nav-right-2; 
  padding-right: 14px;
  display: flex; 
  flex-direction: row; 
  justify-content: flex-end; /* Align items to the end */
  z-index: 2; 
  width: 100%; /* Span across entire free space */
}

.env-span{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  opacity: 0.5;
  text-align: center;
}

</style>